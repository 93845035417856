const Title = () => {
    return (
        <div
        className="title"
        >
            Toact
        </div>
    )
}

export default Title
